import { Box, Button, Paragraph, Span } from '@components'
import Image from 'next/image';
import React from 'react'

type Props = {
    imgUrl: string
    description: string;
    date: any;
    admin: string;
    btn: string;
    callback?: () => void
}

const News = ({ imgUrl, description, date, admin, btn, callback }: Props) => {

    const handleClick = () => {
        if (callback) callback()
    }

    return (
        <Box className='flex flex-col  rounded-2xl shadow gap-5 relative bg-white'>
            <Button onClick={() => handleClick()} className='absolute hover:!bg-transparent top-0 left-0 bottom-0 right-0 z-10' />
            <Box>
                <Image className='rounded-2xl rounded-b-none' alt={imgUrl} layout='responsive' src={imgUrl} objectFit='cover' width={356} height={236} />
            </Box>
            <Paragraph className='font-bold text-xl leading-7 text-[#1C6162] px-6 text-center'>{description}</Paragraph>
            <Box className='flex justify-between px-8'>
                <Span className='text-sm leading-7 text-[#69798D]'>{date}</Span>
                <Span className='text-sm leading-7 text-[#69798D]'>{admin}</Span>
            </Box>
            <Button variant='secondary' className='font-medium mx-6 mb-6 z-10'>{btn}</Button>
        </Box>
    )
}

export default News