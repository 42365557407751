import { Box, Container, Title } from '@components'
import React from 'react'
import News from './news'
import SectionSeperator from '../layout/section-seperator'

const NewsCardSection = () => {
    return (
        <SectionSeperator className='pt-0'>
            <Container>
                <Title className='text-center font-bold text-4xl text-[#1C6162] mb-7'>IN THE NEWS</Title>

                <Box className='grid grid-cols-3 gap-10'>
                    <News
                        imgUrl="/images/home/5.png"
                        description='We’ve Joined Intuit’s Climate Action marketplace'
                        date='May 03, 2022'
                        admin='By Admin'
                        btn='Read More' />
                    <News
                        imgUrl="/images/home/6.png"
                        description='We’ve Joined Intuit’s Climate Action marketplace'
                        date='May 03, 2022'
                        admin='By Admin'
                        btn='Read More' />
                    <News
                        imgUrl="/images/home/7.png"
                        description='We’ve Joined Intuit’s Climate Action marketplace'
                        date='May 03, 2022'
                        admin='By Admin'
                        btn='Read More' />
                </Box>

            </Container>
        </SectionSeperator >
    )
}

export default NewsCardSection